import { DSCBCoupledPosts, DSPostShortWithAuthor } from 'shared-definitions/types'
import PostRowSimple from 'shared-components/posts/PostRowSimple'
import styles from './CBCoupledPosts.module.css'
import BasicButtonLink from 'shared-components/buttons/BasicButtonLink'
import { useCallback } from 'react'
import PostRow from 'shared-components/posts/PostRow'
import LoadMoreList from 'shared-components/ui/LoadMoreList'
import { getds } from 'ds'
import { CommonDataContext } from 'shared-components/contexts/CommonDataContext'
import Freestar from 'shared-components/ad/Freestar'

interface CBCoupledPostsProps {
  data: DSCBCoupledPosts
}

const CBCoupledPosts: React.VFC<CBCoupledPostsProps> = ({ data }) => {
  const { ts } = CommonDataContext.useContainer()
  const pageLoader = useCallback(
    async (pageNumber: number, perPage: number) => {
      const dataSource = await getds({ ts })
      const result = await dataSource.getPostsList(data.taxonomy, pageNumber, perPage)
      return result.data.items
    },
    [data.taxonomy, ts]
  )

  return (
    <div className={styles.container}>
      <div className={styles.secondary}>
        <h4 className={styles.title}>{data.subtitle}</h4>
        {data.secondary.map((item, index) => (
          <PostRowSimple post={item} key={index} />
        ))}
        <Freestar slotId="home_rightrail" placementName={'soundguys_homepage_rightrail'} />
        <BasicButtonLink {...data.button.pLink} className={styles.button}>
          {data.button.label}
        </BasicButtonLink>
      </div>
      <div className={styles.primary}>
        <h4 className={styles.title}>{data.title}</h4>
        <LoadMoreList
          autoload
          render={useCallback(
            (item: DSPostShortWithAuthor, index) => (
              <PostRow key={index} post={item} large />
            ),
            []
          )}
          className={styles.primaryList}
          initial={data.posts}
          loader={pageLoader}
        />
      </div>
    </div>
  )
}

export default CBCoupledPosts
