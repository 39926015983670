import ContentBlock from 'home/ContentBlock'
import Freestar from 'shared-components/ad/Freestar'
import { useTaglessAd } from 'shared-components/hooks/use-tagless-ad'
import styleUtils from 'shared-components/styles/util.module.css'
import Disclaimer from 'shared-components/ui/Disclaimer'
import { DSHomePage } from 'shared-definitions/types'
import { DSContentBlockSG } from 'types.sg.ds'
import styles from './HomeTemplate.module.css'

type LayoutItem =
  | { variant: 'ad'; placementName: FreestarPlacementName }
  | { variant: 'grid'; items: DSContentBlockSG[] }

function mixComponentBlocks(items: readonly DSContentBlockSG[]): LayoutItem[] {
  const layout: LayoutItem[] = [
    {
      variant: 'grid',
      items: items.slice(0, 1),
    },
    {
      variant: 'ad',
      placementName: 'soundguys_incontent_1',
    },
    {
      variant: 'grid',
      items: items.slice(1, 5),
    },
    {
      variant: 'ad',
      placementName: 'soundguys_incontent_2',
    },
  ]

  const restBlocks = items.slice(5)
  for (let i = 0; i < restBlocks.length; i += 4) {
    const subItems = restBlocks.slice(i, i + 4)
    layout.push({
      variant: 'grid',
      items: subItems,
    })

    // do not place ad after lists with articles
    if (restBlocks.length > i + 3) {
      layout.push({
        variant: 'ad',
        placementName: `soundguys_incontent_${i}` as FreestarPlacementName,
      })
    }
  }
  return layout
}

interface HomeTemplateProps {
  page: DSHomePage<DSContentBlockSG>
}

const HomeTemplate: React.VFC<HomeTemplateProps> = ({ page }) => {
  useTaglessAd(['bottom_rail', 'flex_leaderboard'])

  const layout = mixComponentBlocks(page.blocks)

  return (
    <div className={styleUtils.wrapper}>
      <Disclaimer />
      {layout.map((part, partIndex) => {
        if (part.variant === 'ad') {
          const { placementName } = part
          return (
            <div key={partIndex} className={styles.leaderboard}>
              <Freestar
                slotId={`home_tpl_a_${partIndex}`}
                placementName={placementName}
                className={styleUtils.mobileFull}
              />
            </div>
          )
        }

        return (
          <div className={styles.grid} key={partIndex}>
            {part.items.map((item, index) => (
              <ContentBlock key={index} index={index} data={item} tpl="home" />
            ))}
          </div>
        )
      })}
    </div>
  )
}

export default HomeTemplate
