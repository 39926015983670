import styles from './PostRowSimple.module.css'
import clsx from 'clsx'
import { BasicStyledComponent, DSPostShort } from 'shared-definitions/types'
import AppImage from 'shared-components/ui/AppImage'
import Linked from 'shared-components/service/Linked'
import Score from 'shared-components/ui/Score'

interface PostRowSimpleProps extends BasicStyledComponent {
  post: DSPostShort
}

const PostRowSimple: React.VFC<PostRowSimpleProps> = ({ post, className }) => (
  <Linked className={clsx(styles.container, className)} {...post.pLink}>
    {post.image ? (
      <AppImage layout="raw" image={post.image} className={styles.imageContainer} sizes={70} />
    ) : null}
    <div className={styles.desc}>
      <h5 className={styles.title}>{post.title}</h5>
      {post.score ? <Score className={styles.score} score={post.score} /> : null}
      <div className={styles.seeAlso}>{'See Review'}</div>
    </div>
  </Linked>
)

export default PostRowSimple
