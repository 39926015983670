import styles from './Tile.module.css'
import clsx from 'clsx'
import { BasicStyledComponent, DSTile } from 'shared-definitions/types'
import AppImage from 'shared-components/ui/AppImage'
import Linked from 'shared-components/service/Linked'
import TileIcon from 'shared-components/service/TileIcon'

interface TileProps extends BasicStyledComponent {
  tile: DSTile
}

const Tile: React.VFC<TileProps> = ({ className, tile }) => (
  <Linked {...tile.pLink} className={clsx(styles.container, className)}>
    <AppImage
      className={styles.imageContainer}
      image={tile.image}
      layout="fill"
      sizes={[
        { bp: 'lg', val: 270 },
        { bp: 'sm', val: 'calc(calc(100vw - rem(45)) / 2)' },
        'calc(100vw - rem(30))',
      ]}
    />
    <TileIcon icon={tile.icon} className={styles.icon} />
    <span className={styles.title}>{tile.title}</span>
  </Linked>
)

export default Tile
